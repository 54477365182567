import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material/';

import { useResponsive } from 'src/hooks/use-responsive';

import { useLocales, useTranslate } from 'src/locales';

import LoginAr from './loginAr.mp4';
import LoginEn from './loginEn.mp4';
import VideoAr from './clinicAr.mp4';
import VideoEn from './clinicEn.mp4';
import Language from '../common/language-popover';

// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, title }) {
  const { t } = useTranslate();
  const mdUp = useResponsive('up', 'md');
  const { currentLang } = useLocales();
  const curLangAr = currentLang.value === 'ar';
  const renderContent = (
    <Stack sx={{ width: { sm: '100vw', md: '100%', lg: '35%' } }}>
      <Stack
        sx={{
          width: '100%',
          mx: 'auto',
          maxWidth: 480,
          pl: { xs: 2, md: 1 },
          pr: { xs: 2, md: 4 },
          pt: { xs: 15, md: 5 },
          pb: { xs: 5, md: 0 },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={0.5}
      spacing={5}
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: '#FFFFFF',
      }}
    >
          <Language />
      <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
        {t(title)}
      </Typography>
      {title === 'Happy to see you again' ? (
        <video style={{ width: '70%', height: '60%' }} loop autoPlay muted>
          <source src={curLangAr ? LoginAr : LoginEn} type="video/mp4" />
          <track
            kind="captions"
            srcLang="en"
            src="path/to/your/captions.vtt"
            label="English"
            default
          />
        </video>
      ) : (
        <video style={{ width: '70%', height: '60%' }} loop autoPlay muted>
          <source src={curLangAr ? VideoAr : VideoEn} type="video/mp4" />
          <track
            kind="captions"
            srcLang="en"
            src="path/to/your/captions.vtt"
            label="English"
            default
          />
        </video>
      )}
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction={mdUp ? 'row' : 'column'}
      sx={{
        minHeight: '100%',
      }}
    >
      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
